import { exact, oneOf, shape, string } from "prop-types";
import { useCallback } from "react";
import { useHistory, useLocation } from "react-router";

import { sortDirectionName, sortName } from "web/constants/orders";

import SortItem from "./sortItem";

const OrdersSortItemContainerMobile = ({
  title,
  subtitle,
  name,
  value,
  classes,
  parent,
  defaultSort,
}) => {
  const { search, pathname } = useLocation();
  const { push } = useHistory();
  const params = new URLSearchParams(search);
  const sortingDirection =
    params.get(sortDirectionName) || defaultSort?.direction;
  const sortingName = params.get(sortName) || defaultSort?.name;
  const classNameLink =
    (sortingName === name && sortingDirection === value) ||
    (!value && !params.get(sortName) && !params.get(sortDirectionName))
      ? classes.buttonActive
      : classes.button;
  const clickHandler = useCallback(
    (event) => {
      const scrollContainer =
        document.getElementsByClassName(parent)[0].children[0];

      if (scrollContainer.scrollWidth > scrollContainer.clientWidth) {
        const button = event.currentTarget;
        const buttonRect = button.getBoundingClientRect();

        if (buttonRect.x + buttonRect.width > scrollContainer.clientWidth) {
          scrollContainer.scrollLeft =
            buttonRect.x + buttonRect.width - scrollContainer.clientWidth;
        }

        if (buttonRect.x < 0) {
          scrollContainer.scrollLeft = button.offsetLeft;
        }
      }

      const paramsAdditional = new URLSearchParams(search);

      paramsAdditional.delete(sortName);
      paramsAdditional.delete(sortDirectionName);
      paramsAdditional.delete("page");

      if (value) {
        paramsAdditional.set(sortName, name);
        paramsAdditional.append(sortDirectionName, value);
      }

      push(`${pathname}?${paramsAdditional.toString()}`);
    },
    [pathname, search, push]
  );

  return (
    <SortItem
      title={title}
      subtitle={subtitle}
      clickHandler={clickHandler}
      className={classNameLink}
    />
  );
};

OrdersSortItemContainerMobile.propTypes = {
  classes: shape({
    button: string,
    buttonActive: string,
  }).isRequired,
  title: string.isRequired,
  subtitle: string,
  name: string,
  value: string,
  parent: string.isRequired,
  defaultSort: exact({ name: string, direction: oneOf(["DESC", "ASC"]) }),
};

OrdersSortItemContainerMobile.defaultProps = {
  subtitle: "",
  name: "",
  value: "",
  defaultSort: null,
};

export default OrdersSortItemContainerMobile;
